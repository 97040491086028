<template>
  <div class="main">
    <el-row>
      <p class="title">{{ $t("personal.openApi.basicInfo.title") }}</p>
    </el-row>
    <el-row>
      <el-col class="title_2" :offset="1">
        <p>{{ $t("personal.openApi.basicInfo.id") }}:&nbsp;</p>
        <span>{{ tenant_user_id }}</span>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col v-if="API_secret" class="title_2" :offset="1">
        <p>API Secret:&nbsp;&nbsp;</p>
        <el-input
          v-model="API_secret"
          disabled
          size="small"
          style="width: 700px"
        ></el-input>
        <el-button size="small" @click="handleCopyText(API_secret)">{{
          $t("btn.copyBtn")
        }}</el-button>
      </el-col>
      <el-col v-else class="title_2" :offset="1">
        <p>API Secret:&nbsp;&nbsp;</p>
        <el-button type="primary" size="small" @click="create_open_api()">{{
          $t("personal.openApi.basicInfo.add")
        }}</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-alert
          class="tip"
          show-icon
          :closable="false"
          :title="$t('personal.openApi.basicInfo.note')"
          type="info"
        >
        </el-alert>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="1">
        <el-button type="danger" size="small" @click="refresh_open_api()">{{
          $t("personal.openApi.basicInfo.resetBtn")
        }}</el-button>
      </el-col>
    </el-row>
    <br />
    <br />
    <br />
    <br />
    <br />
    <el-row>
      <p class="title">{{ $t("personal.openApi.use.title") }}</p>
    </el-row>
    <el-row>
      <el-col :offset="1">
        <el-button size="small" type="primary" @click="show_doc_dialog()">{{
          $t("personal.openApi.use.btn1")
        }}</el-button>
        <el-button size="small" type="primary" @click="go_api_doc()">{{
          $t("personal.openApi.use.btn2")
        }}</el-button>
      </el-col>
    </el-row>

    <el-dialog
      class="outdialog"
      :lock-scroll="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="true"
      :show-close="true"
      top="2%"
      width="75%"
    >
      <div class="dialog">
        <el-row class="dialog-title">{{
          $t("personal.openApi.add.p1")
        }}</el-row>
        <el-row>{{ $t("personal.openApi.add.p2") }}</el-row>
        <el-row>
          <img style="width: 100%" src="@/assets/img/newOpenApi1.png" />
        </el-row>
        <br />
        <el-row class="dialog-title">
          {{ $t("personal.openApi.add.p3") }}
        </el-row>
        <el-row>
          {{ $t("personal.openApi.add.p4") }}
        </el-row>
        <el-row>
          <li>X-TENANT-ID</li>
          <li>X-MS-SECRET</li>
        </el-row>
        <br />
        <el-row class="dialog-title">
          {{ $t("personal.openApi.add.p5") }}
        </el-row>
        <el-row>
          {{ $t("personal.openApi.add.p6") }}
        </el-row>
        <el-row>
          <img style="width: 100%" src="@/assets/img/newOpenApi2.png" />
        </el-row>
        <br />
        <el-row class="dialog-title">
          {{ $t("personal.openApi.add.p7") }}
        </el-row>
        <el-row>
          {{ $t("personal.openApi.add.p8")
          }}<a
            target="_blank"
            href="https://ms.ytdevops.com/pubKnowledge/815d96c6-78b6-4e78-8813-00bf79473f07"
            >{{ $t("personal.openApi.add.p9") }}</a
          >
        </el-row>
        <br />
        <el-row>
          <strong>{{ $t("personal.openApi.add.p10") }}</strong
          >{{ $t("personal.openApi.add.p11") }}
        </el-row>
        <el-row>
          {{ originUrl }}/file-manage-service/mso/{
          {{ $t("personal.openApi.add.p12") }} }/fileTypes
        </el-row>
        <el-row>
          <el-button
            style="float: right"
            type="text"
            autofocus
            size="small"
            @click="handleCopyText(egValue)"
            >{{ $t("btn.copyBtn") }}</el-button
          >
          <el-input
            resize="none"
            type="textarea"
            autosize
            :readonly="true"
            :value="egValue"
          >
          </el-input>
        </el-row>
      </div>
      <span slot="title" class="dialog-header">
        {{ $t("personal.openApi.add.p13") }}
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  get_open_api,
  refresh_open_api,
  create_open_api,
} from "@/network/openApi/index.js";
export default {
  data() {
    return {
      API_secret: "",
      dialogVisible: false,
    };
  },
  computed: {
    egValue() {
      return `curl -X GET '${process.env.VUE_APP_BASE_API}/file-manage-service/mso/44b0f1bb-3992-4bfc-a7f8-53bb6aa118e5/fileTypes' -H 'X-TENANT-ID: eb56f0d7-53ab-43df-be7e-6e0246a3a9' -H 'X-MS-SECRET: 99c5c054f4fc513f77663f78f29e28669008af9900c9dbe8228ca452de20c0'`;
    },
    originUrl() {
      return process.env.VUE_APP_BASE_API;
    },
    tenant_user_id() {
      return this.$store.getters.tenant_id;
    },
  },
  mounted() {
    this.get_open_api();
  },
  methods: {
    show_doc_dialog() {
      this.dialogVisible = true;
    },
    get_open_api() {
      get_open_api().then((res) => {
        this.API_secret = res.msSecret;
      });
    },
    refresh_open_api() {
      refresh_open_api().then((res) => {
        this.$message.success(this.$t("personal.openApi.message"));
        this.API_secret = res;
      });
    },
    create_open_api() {
      create_open_api().then((res) => {
        this.$message.success(this.$t("personal.openApi.message2"));
        this.API_secret = res;
      });
    },
    go_api_doc() {
      window.open(this.$t("openApiGuide"), "_blank");
    },
    //复制
    handleCopyText(id) {
      // 创建一个 Input标签
      const cInput = document.createElement("input");
      cInput.value = id;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本域内容;
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效
      document.execCommand("Copy");
      this.$message.success(this.$t("personal.openApi.message3"));
      /// 复制成功后再将构造的标签 移除
      cInput.remove();
    },
  },
};
</script>

<style lang="scss" scoped>
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.main {
  color: rgb(112, 112, 112);
  text-align: left;
}

.title {
  font-size: 18px;
  text-align: left;
}
.title_2 {
  display: flex;
  align-items: center;
  font-size: 16px;
  span {
    font-size: 16px;
  }
}
.copy-btn {
  height: 50px;
  width: 90px;
}
.tip {
  padding: 0;
  margin: 20px 0px 20px 55px;
}
.outdialog {
  overflow: hidden;

  ::v-deep .el-dialog {
    height: 93%;
    overflow: auto;
  }
}
.dialog {
  padding: 0 50px 50px 50px;
  line-height: 35px;
  color: black;

  ul {
    list-style-type: disc;
    font-size: 20px;
  }
  &-header {
    font-size: 30px;
    color: black;
    font-weight: bold;
  }
  &-title {
    font-size: 18px;
    color: black;
    font-weight: bold;
  }
}
::v-deep .el-dialog__close{
  position: fixed;
  z-index: 999;
}
</style>
